.task-card-description{
  width: 100% !important;
  font-size: smaller;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.task-list-card-content{
  //font-size: smaller;
  padding: 0 20px 10px;

  .task-card-bottom{
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}


.task-details-container{
  width: 100%;
  padding: 0;
  margin: 0 0 10px;
  display: flex;
  flex-direction: row;
  gap: 2em;
  justify-content: flex-start;

}

.search-filters{
  width: 100%;
  padding: 0 2em;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10em;
}
