.multiple-users-list-container{
  width: 100%;

  .multiple-users-list{
    overflow-y: scroll;
    width: 100%;
    margin: 0 auto;
    height: 40vh;
  }
}
