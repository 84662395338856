.chat-messages-container{
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: .5em;

  .chat-message-list{
    padding: 0 20px;
    width: 100%;
    height: 80%;
    max-height: 80%;
    overflow-y: scroll;
    //background: #f0f4f9;
  }
  .text-box-container{
    border-top: .5px solid #cccccc;
    padding: 8px;
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .chat-text-area{
      border-radius: 10px;
    }

    .text-box-container-actions{

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
