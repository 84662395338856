.drag-drop-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .drop-area{
    flex: 1;
    width: 100%;
    border: 1px solid #aaaaaa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #ececec;

    p{
      padding: 0;
      margin: 0;
      font-style: italic;
    }

    .drop-area-icon{
      font-size: 60px;
      color: #1d39c4;
    }

  }

  .list-area{
    flex: 1;
    width: 100%;
  }
}


.upload-attachments-container{
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5em;
  //background: #ececec;

  .upload-container{
    //background: #ececec;
    flex: 2;
    display: flex;
    padding: 12px;
    flex-direction: row;
    justify-content: center;


  }

  .upload-progress-container{
    width: 100%;
    flex: 2;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

  }
}
