@import "~antd/dist/antd.css";
/* @import "antd-country-phone-input/dist/index.css"; */

.menuBar {
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  /* overflow: auto; */
  box-shadow: 0 0 30px #f3f1f1;
  height: 50px;
  overflow: hidden;
  background-color: #001529;
}

.logo {
  width: 90px;
  height: 50px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  /* padding: 0px 20px; */
  text-transform: capitalize;
}

.menuCon {
  /* width: calc(100% - 150px); */
  width: calc(100% - 125px);
  float: left;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  color: white;
  padding: 0px 10px;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: white;
}

/* .menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
} */

.menuCon .ant-menu-horizontal {
  border-bottom: none;
  background-color: #001529;
}

.menuCon .leftMenu {
  float: left;
  width: 500px;
}

.menuCon .rightMenu {
  float: right;
  width: 100px;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  .ant-table table {
    font-size: 14px;
  }

  .ant-descriptions-item-label {
    font-size: 14px;
  }

  .ant-descriptions-item-content {
    font-size: 14px;
  }

  .ant-select {
    font-size: 14px;
  }

  .ant-card-head {
    font-size: 16px;
  }

  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 16px 24px;
  }

  .ant-btn {
    font-size: 14px;
  }

  .ant-card {
    font-size: 14px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .ant-page-header {
    padding: 1px 5px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 0px 5px;
  }

  .ant-table table {
    font-size: 10px;
  }

  .ant-btn {
    font-size: 12px;
  }

  .ant-list-item-meta-title {
    font-size: 12px;
  }

  .ant-list-sm .ant-list-item {
    padding: 0px;
  }

  .ant-card-head {
    font-size: 12px;
  }

  .ant-card {
    font-size: 12px;
  }

  .ant-descriptions-item-label {
    font-size: 10px;
  }

  .ant-descriptions-item-content {
    font-size: 10px;
  }

  .ant-select {
    font-size: 10px;
  }

  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 1px 5px;
  }
}
